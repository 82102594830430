// custom typefaces
import "@fontsource/lato"
import "@fontsource/lato/700.css"
import "@fontsource/roboto-slab/700.css"

// We're using Gutenberg so we need the block styles
import "@wordpress/block-library/build-style/style.css"
import "@wordpress/block-library/build-style/theme.css"

// custom CSS styles
import './src/styles/global.css';

// Simple React lightbox (used for galleries)
import React from 'react'
import SimpleReactLightbox from 'simple-react-lightbox'

// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => (
  <SimpleReactLightbox>{element}</SimpleReactLightbox>
)